<template>
  <div class="action-component">
    <div class="row">
      <div class="col-3">
        <select v-model="selectedAction" :value="automationAction" class="form-select" @change="localConfiguration = {}">
          <option :value="null" disabled>Select an action</option>
          <option :value="action.name" v-for="action, key in actions" :key="key">{{action.label}}</option>
        </select>
      </div>
    </div>
    <div class="row mt-2" v-if="selectedAction">
      <div class="col-12">
        <component :is="selectedAction" :key="selectedAction" v-model="localConfiguration" :automationmodel="automationModel"></component>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "./../../http.js";
import SendEmail from "./actions/SendEmail.vue";
import Slack from "./actions/Slack.vue";
import Log from "./actions/Log.vue";
export default {
  props: ["automationModel", "automationAction", "automationConfiguration"],
  components: {
    SendEmail,
    Slack,
    Log,
  },
  data() {
    return {
      actions: [],
      localConfiguration: this.automationConfiguration,
      selectedAction: null,
      action: {},
    };
  },
  mounted() {
    this.getActions();
    this.selectedAction = this.automationAction;
  },
  computed: {},
  methods: {
    getActions() {
      this.actions = [];
      axios.get("/api/admin/automations/actions").then((response) => {
        if (response.data) {
          this.actions = response.data;
        }
      });
    },
  },
  watch: {
    selectedAction(val) {
      this.$emit("actionchanged", val);
    },
    localConfiguration: {
      deep: true,
      handler(val) {
        this.$emit("configurationchanged", val);
      },
    },
  },
};
</script>

<style>
.action-component {
}
</style>