<template>
  <select :id="id" v-model="input" class="form-select">
    <option v-for="option,k in options" :key="k" :value="k">{{option}}</option>
  </select>
</template>

<script>
export default {
  props: ["options", "value"],
  data() {
    return {
      input: this.value,
    };
  },
  computed: {
    id() {
      return "text_input_" + this._uid;
    },
  },
  watch: {
    input(input) {
      this.$emit("input", input);
    },
    value(value) {
      this.input = value;
    },
  },
};
</script>