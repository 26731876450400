<template>
  <div class="actions-send-email">
    <div class="row">
      <div class="col">
        <label>Message:</label>
        <input type="text" class="form-control" v-model="localConfiguration.message" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["value"],
  data() {
    return {
      localConfiguration: {
        message: "",
      },
      baseUrl: process.env.VUE_APP_VAVEURL,
    };
  },
  mounted() {
    this.localConfiguration = this.value;
  },
  watch: {
    localConfiguration: {
      deep: true,
      handler() {
        this.$emit("input", this.localConfiguration);
      },
    },
  },
};
</script>
