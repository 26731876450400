<template>
  <div class="actions-send-email">
    <div class="row">
      <div class="col-3">
        <label>Email subject:</label>
        <input type="text" v-model="localConfiguration.subject" class="form-control" />
      </div>
      <div class="col-3">
        <label>Recipient:</label>
        <select v-model="localConfiguration.recipient" :value="localConfiguration.recipient" class="form-select">
          <option :value="null" disabled>-</option>
          <option value="customer">Customer</option>
          <option value="customer-service">Customer Service</option>
        </select>
      </div>
      <div class="col-3">
        <label>Template:</label>
        <select v-model="localConfiguration.template" :value="localConfiguration.template" class="form-select">
          <option :value="template.slug" v-for="template in templates" :key="template.id">{{template.name}}</option>
        </select>
      </div>
      <div class="col-3">
        <br />
        <button class="vave-btn btn-outline-blue" v-if="localConfiguration.template" @click="$refs.emailPreview.show()">
          <i class="fa fa-eye"></i> Preview
        </button>
      </div>
    </div>

    <!-- Coupon -->
    <div class="mt-3">
      <coupon :value="couponConfigurationComputed" @couponUpdated="updateCoupon($event)" v-if="localConfiguration.template" />
    </div>

    <!-- Products -->
    <div class="mt-3">
      <products :whichProducts="localConfiguration.products" :includeProducts="localConfiguration.include_products" @productsUpdated="updateProducts($event)" v-if="localConfiguration.template" />
    </div>

    <widget-config-modal ref="emailPreview">
      <template v-slot:title v-if="selectedEmailTemplate">Preview {{selectedEmailTemplate.name}} ({{selectedEmailTemplate.slug}})</template>
      <template v-slot:body>
        <div class="h-100" style="position:relative;">
          <iframe v-if="selectedEmailTemplate" class="preview-iframe" :src="baseUrl + '/api/admin/email-templates/'+ selectedEmailTemplate.id + '/preview' + couponPreviewUrl"></iframe>
        </div>
      </template>
    </widget-config-modal>
  </div>
</template>

<script>
import axios from "./../../../http";
import Coupon from "./components/Coupon";
import Products from "./components/Products";
import { formatValue } from "./../../../formatters";
import WidgetConfigModal from "./../../widgets/WidgetConfigModal";

export default {
  props: ["automationmodel", "value"],
  data() {
    return {
      templates: [],
      localConfiguration: {},
      baseUrl: process.env.VUE_APP_VAVEURL,
    };
  },
  components: {
    WidgetConfigModal,
    Coupon,
    Products,
  },
  watch: {
    automationmodel() {
      // Model changed, let's reset the component
      this.getTemplates();
    },
    localConfiguration: {
      deep: true,
      handler() {
        this.$emit("input", this.localConfiguration);
      },
    },
  },
  computed: {
    couponPreviewUrl() {
      let queryString = "?";
      if (
        this.localConfiguration.coupon &&
        this.localConfiguration.coupon.code
      ) {
        queryString += "coupon=" + this.localConfiguration.coupon.code;
      }
      if (
        this.localConfiguration.coupon &&
        this.localConfiguration.coupon.amount
      ) {
        queryString +=
          "discount_amount=" +
          (this.localConfiguration.coupon.discount_type == "percent"
            ? this.localConfiguration.coupon.amount + "%"
            : "£" + this.localConfiguration.coupon.amount);
      }
      return queryString;
    },
    couponConfigurationComputed() {
      let data = {
        include_coupon: this.localConfiguration.include_coupon,
      };
      if (
        this.localConfiguration.include_coupon &&
        this.localConfiguration.coupon
      ) {
        data.coupon_confirmed = true;
        data.discount_type = this.localConfiguration.coupon.discount_type;
        data.generate_new = this.localConfiguration.coupon.generate_new;
        data.amount = this.localConfiguration.coupon.amount;
        data.coupon_code = this.localConfiguration.coupon.code;
      }
      return data;
    },
    selectedEmailTemplate() {
      return this.templates.find(
        (t) => t.slug == this.localConfiguration.template
      );
    },
  },
  mounted() {
    this.localConfiguration = this.value;
    if (!this.automationmodel) {
      return;
    }
    this.getTemplates();
  },
  methods: {
    updateTracking(val) {
      if (val == true) {
        this.$set(this.localConfiguration, "include_tracking", true);
      } else {
        this.$set(this.localConfiguration, "include_tracking", false);
      }
    },
    updateProducts(val) {
      if (Object.keys(val).length > 0) {
        this.$set(this.localConfiguration, "include_products", true);
        this.$set(this.localConfiguration, "products", val);
      } else {
        this.$set(this.localConfiguration, "include_products", false);
        this.$delete(this.localConfiguration, "products");
      }
    },
    updateCoupon(val) {
      if (Object.keys(val).length > 0) {
        this.$set(this.localConfiguration, "include_coupon", true);
        this.$set(this.localConfiguration, "coupon", val);
      } else {
        this.$set(this.localConfiguration, "include_coupon", false);
        this.$delete(this.localConfiguration, "coupon");
      }
    },
    formatValue,
    getTemplates() {
      axios
        .get(
          "api/admin/email-templates?status=succeeded&model=" +
            this.automationmodel
        )
        .then((response) => {
          this.templates = response.data.data;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.actions-send-email {
  .preview-iframe {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1;
    width: 100%;
    min-height: 100%;
    padding-top: 4em;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
  }
  .include-coupon-btn {
    font-size: 1.5em;
    margin-left: 5px;
    cursor: pointer;
    color: lighten(#2980b9, 15%);
    &:hover {
      color: #2980b9;
    }
  }
  .remove-coupon-btn {
    cursor: pointer;
    color: #888;
    &:hover {
      color: rgb(244, 104, 104);
    }
  }
}
</style>