 
 <template>
  <div class="content">
    <h4>Create Automation</h4>
    <div class="create">
      <div class="row">
        <div class="col">
          <div class="block" :class="{'block-with-arrow' : automation.model}">
            <div class="row">
              <div class="col-4">
                Description:
                <input type="text" class="form-control" v-model="automation.description" />
                <div v-for="(error, k) in errors.description" :key="k" v-html="errors.description[k]"></div>
              </div>
              <div class="col-auto">
                Choose a Model:
                <select v-model="automation.model" class="form-select" :disabled="modelSelectDisabled">
                  <option :value="model" v-for="model, key in models" :key="key">{{model}}</option>
                </select>
              </div>
              <div class="col-auto">
                <br />
                <div class="form-check">
                  <input class="form-check-input" type="checkbox" v-model="shouldRunOnce" id="checkRunOnce" />
                  <label class="form-check-label" for="checkRunOnce">Run only once per Model</label>
                </div>
              </div>
              <div class="col-auto" :title="'total models: ' + count">
                Ignore Models older than:
                <select v-model="ignoreModelsOlderThanMinutes" class="form-select" :disabled="!automation.model">
                  <!-- We add 1 hourish just to be safe the models are not missed -->
                  <option :value="null">-</option>
                  <option value="45">45 Minutes</option>
                  <option value="60">1 Hour</option>
                  <option value="90">1 Hour and half</option>
                  <option value="1440">1 Day</option>
                  <option value="2880">2 Day</option>
                  <option value="11520">8 Days</option>
                  <option value="21600">15 days</option>
                  <option value="44640">31 days</option>
                  <option value="87840">2 months</option>
                  <option value="262080">6 months</option>
                  <option value="525600">1 year</option>
                </select>
              </div>
              <div class="col-auto">
                Run every:
                <select v-model="runEvery" class="form-select" :disabled="loadingCount">
                  <!-- We add 1 hourish just to be safe the Models are not missed -->
                  <option value="minute" :disabled="disableRunEveryMinute">Minute</option>
                  <option value="hour">Hour</option>
                  <option value="day">Day (09:00)</option>
                  <option value="night">Night (00:00)</option>
                  <option value="week">Week</option>
                  <option value="month">Month</option>
                </select>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row" v-if="automation.model">
        <div class="col">
          <div class="block block-with-arrow">
            <div class="row">
              <div class="col">
                <conditions-component v-model="automation.conditions" :automationModel="automation.model" />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row" v-if="automation.model">
        <div class="col">
          <div class="block">
            <actions-component @configurationchanged="automation.configuration = $event" @actionchanged="automation.action = $event" :automationModel="automation.model" :automationAction="automation.action" :automationConfiguration="automation.configuration" />
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col">
          <button class="vave-btn btn-green" @click="createNewAutomation()" :disabled="!this.automation.action || !this.automation.model">Save</button>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import axios from "../http.js";
import ConditionsComponent from "./automations/Conditions.vue";
import ActionsComponent from "./automations/Actions.vue";

export default {
  data() {
    return {
      errors: {},
      models: [],
      automation: {
        description: "",
        action: null,
        model: null,
        configuration: { run_every: "day" },
        conditions: [],
      },
      automationsList: {},
      shouldRunOnce: true,
      ignoreModelsOlderThanMinutes: 1440,
      runEvery: "day",
      loadingCount: true,
      count: null,
    };
  },
  components: {
    ConditionsComponent,
    ActionsComponent,
  },
  computed: {
    shouldRefreshModelsCount() {
      return this.automation.model + this.ignoreModelsOlderThanMinutes;
    },
    disableRunEveryMinute() {
      return this.count > 200;
    },
    modelSelectDisabled() {
      let conditions = Object.keys(this.automation.conditions).length > 0;
      // Add additional conditions
      return conditions;
    },
  },
  mounted() {
    this.retrieveModels();
  },
  watch: {
    shouldRefreshModelsCount() {
      this.getModelsCount();
    },
    disableRunEveryMinute() {
      if (this.runEvery == "minute") {
        this.runEvery = "hour";
        this.$toast.warning(
          "The automation is too computationally expensive to run every minute. If you really need to run it every minute, try to ignore older models."
        );
      }
    },
  },
  methods: {
    retrieveModels() {
      this.models = [];
      axios.get("/api/admin/automations/models").then((response) => {
        if (response.data) {
          this.models = response.data;
        }
      });
    },
    createNewAutomation() {
      this.errors = {};
      this.automation.configuration.should_run_once = this.shouldRunOnce;
      this.automation.configuration.ignore_models_older_than_minutes =
        this.ignoreModelsOlderThanMinutes;
      this.automation.configuration.run_every = this.runEvery;
      axios.post("/api/admin/automations", this.automation).then(
        () => {
          this.$toast.success("Automation created");
          // Reset the form
          this.automation = {
            description: "",
            action: null,
            model: null,
            configuration: {},
            conditions: [],
          };
          this.$router.push("/automations");
        },
        (e) => {
          if (e.response && e.response.data && e.response.data.errors) {
            this.errors = e.response.data.errors;
          }
          this.$toast.error("Error creating automation");
        }
      );
    },
    getModelsCount() {
      this.loadingCount = true;
      axios
        .get("/api/admin/automations/triggers/preview", {
          params: {
            model: this.automation.model,
            ignore_models_older_than_minutes: this.ignoreModelsOlderThanMinutes,
            conditions: [],
            include_models: 0,
          },
        })
        .then((response) => {
          this.loadingCount = false;
          if (response.status == 200) {
            this.count = response.data.total;
          }
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.create {
  padding: 20px;
  background: white;
  .block {
    background: #f0f0f0;
    padding: 20px;
    margin-bottom: 10px;
    // border: 1px solid black;
    border-bottom: 1px solid #ddd;
    position: relative;
  }
  .block-with-arrow::after {
    content: "";
    position: absolute;
    left: 42%;
    bottom: -16px;
    width: 30px;
    height: 30px;
    transform: rotate(45deg);
    background: #f0f0f0;
    z-index: 1;
    border: 1px solid #ddd;
    border-top: 1px solid transparent;
    border-left: 1px solid transparent;
  }
}
</style>