<template>
  <div>
    <input type="number" class="form-control" :id="id" v-model="input" :readonly="readonly" :min="min" :max="max" :placeholder="placeholder" />
  </div>
</template>

<script>
export default {
  props: [
    "placeholder",
    "min",
    "max",
    "value",
    "defaultValue",
    "label",
    "readonly",
  ],
  data() {
    return {
      input: this.defaultValue,
    };
  },
  mounted() {
    this.$emit("input", this.input);
  },
  computed: {
    id() {
      return "text_input_" + this._uid;
    },
  },
  watch: {
    input(input) {
      this.$emit("input", input);
    },
    value(amount) {
      this.input = amount;
    },
  },
};
</script>