<template>
  <div class="conditions-component">
    <div class="row" v-if="Object.keys(localConditions).length">
      <div>Conditions:</div>
      <div class="col-12">
        <table class="table table-borderless align-middle">
          <tbody>
            <tr v-for="condition,k in localConditions" :key="k" class="condition-row">
              <td>{{condition.trigger.label}}</td>
              <td>{{condition.operator.label}}</td>
              <td>
                <span :title="condition.value.value">{{condition.value.label}}</span>
              </td>
              <td style="width: 200px;">
                <div class="condition-buttons">
                  <div class="condition-btn remove-condition-btn" @click="removeCondition(k)">
                    <i class="fa-solid fa-xmark"></i>
                  </div>
                  <div class="condition-btn add-condition-btn" v-if="(k == localConditions.length - 1) && !showAddCondition" @click="showAddCondition = true">
                    <svg class="svg-inline--fa fa-plus-circle" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="plus-circle" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" data-fa-i2svg>
                      <path
                        fill="currentColor"
                        d="M0 256C0 114.6 114.6 0 256 0C397.4 0 512 114.6 512 256C512 397.4 397.4 512 256 512C114.6 512 0 397.4 0 256zM256 368C269.3 368 280 357.3 280 344V280H344C357.3 280 368 269.3 368 256C368 242.7 357.3 232 344 232H280V168C280 154.7 269.3 144 256 144C242.7 144 232 154.7 232 168V232H168C154.7 232 144 242.7 144 256C144 269.3 154.7 280 168 280H232V344C232 357.3 242.7 368 256 368z"
                      />
                    </svg>
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div class="row mt-2" v-if="triggersReady && (showAddCondition || localConditions.length == 0)">
      <div class="col-3">
        <select v-model="selectedTrigger" value="a" class="form-select">
          <option :value="null" disabled>Add conditions (optional)</option>
          <option v-for="trigger, key in triggers" :key="key" :value="trigger">{{trigger.label}}</option>
        </select>
      </div>
      <div class="col-3" v-if="selectedTrigger">
        <select v-model="selectedOperator" class="form-select">
          <option v-for="operator,k in selectedTrigger.operators" :key="k" :value="operator">{{operator.label}}</option>
        </select>
      </div>
      <div class="col-3" v-if="selectedOperator">
        <component
          :key="hash(this.selectedOperator)"
          v-model="operatorValue"
          :is="selectedOperator.input.type + 'Input'"
          :placeholder="selectedOperator.input.placeholder"
          :readonly="selectedOperator.input.readonly"
          :min="selectedOperator.input.min"
          :max="selectedOperator.input.max"
          :options="selectedOperator.input.options"
          :defaultValue="selectedOperator.input.value"
        ></component>
      </div>
      <div class="col-auto" v-if="selectedOperator">
        <button class="vave-btn btn-outline-green" @click="addCondition()">add</button>
      </div>
      <div class="col d-flex align-items-center" v-if="showAddCondition && localConditions.length !== 0">
        <div class="condition-btn remove-condition-btn" @click="showAddCondition = false">
          <i class="fa-solid fa-xmark"></i>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { forEach } from "lodash";
import { formatValue } from "./../../formatters";
import hash from "object-hash";
import axios from "./../../http.js";
import checkboxInput from "./inputs/checkbox.vue";
import textInput from "./inputs/text.vue";
import dateInput from "./inputs/date.vue";
import selectInput from "./inputs/select.vue";
import numberInput from "./inputs/number.vue";

export default {
  props: ["value", "automationModel"],
  data() {
    return {
      triggers: [],
      localConditions: [],
      selectedTrigger: null,
      triggersReady: false,
      selectedOperator: null,
      operatorValue: null,
      showAddCondition: false,
    };
  },
  methods: {
    hash,
    formatValue,
    addCondition() {
      var label = "";
      // The selected operator is a Select
      // find in the selectedOperator.input.options the label for this value
      if (this.selectedOperator.input.type == "select") {
        label = this.operatorValue;
        forEach(this.selectedOperator.input.options, (option, key) => {
          if (key == this.operatorValue) {
            label = option;
          }
        });
      } else if (this.selectedOperator.input.type == "number") {
        let prepend = this.selectedOperator.input.placeholder;
        label = (prepend ? prepend + " " : "") + this.operatorValue;
      } else if (this.selectedOperator.input.type == "checkbox") {
        label = this.operatorValue ? "Yes" : "No";
      } else {
        label = this.operatorValue;
      }

      // If the operator is in cents, let's multiply the float by 100
      var operatorValue = this.operatorValue;
      if (this.selectedOperator.input.format == "cents") {
        operatorValue = this.operatorValue * 100;
      }

      this.localConditions.push({
        trigger: {
          value: this.selectedTrigger.name,
          label: this.selectedTrigger.label,
        },
        operator: {
          value: this.selectedOperator.method,
          label: this.selectedOperator.label,
        },
        value: { value: operatorValue, label: label },
      });
      this.selectedTrigger = null;
      this.selectedOperator = null;
      this.operatorValue = null;
      this.showAddCondition = false;
    },
    removeCondition(k) {
      this.localConditions.splice(k, 1);
    },
    getTriggers() {
      this.triggers = [];
      this.selectedTrigger = null;
      this.selectedOperator = null;
      axios
        .get("/api/admin/automations/triggers?model=" + this.automationModel)
        .then((response) => {
          if (response.data) {
            this.triggers = response.data;
            this.triggersReady = true;
            this.convertExistingTriggers();
          }
        });
    },
    convertExistingTriggers() {
      this.localConditions = [];
      this.value.forEach((condition) => {
        this.triggers.forEach((trigger) => {
          if (trigger.name == condition.trigger) {
            // find the operator
            forEach(trigger.operators, (operator) => {
              if (operator.method == condition.operator) {
                let conditionThreshold = condition.threshold;
                // find the input
                let valueLabel = condition.threshold;
                if (operator.input.type == "select") {
                  valueLabel = operator.input.options[condition.threshold];
                }
                if (operator.input.format == "cents") {
                  valueLabel = formatValue(valueLabel, "money");
                  conditionThreshold = conditionThreshold / 100;
                }
                this.localConditions.push({
                  trigger: { label: trigger.label, value: condition.trigger },
                  operator: {
                    label: operator.label,
                    value: condition.operator,
                  },
                  value: {
                    label: valueLabel ? valueLabel : conditionThreshold,
                    value: condition.threshold,
                  },
                });
              }
            });
          }
        });
      });
      this.conditionsReady = true;
    },
  },
  mounted() {
    this.getTriggers();
  },
  watch: {
    localConditions: {
      deep: true,
      handler() {
        this.$emit(
          "input",
          this.localConditions.map((condition) => {
            return {
              trigger: condition.trigger.value,
              operator: condition.operator.value,
              threshold: condition.value.value,
            };
          })
        );
      },
    },
    automationModel() {
      this.getTriggers();
    },
  },
  components: {
    checkboxInput,
    textInput,
    selectInput,
    numberInput,
    dateInput,
  },
};
</script>

<style lang="scss">
.conditions-component {
  .condition-row {
    &:hover {
      background: #fafafa;
    }
  }
  .condition-buttons {
    display: flex;
    justify-content: flex-start;
  }
  .condition-btn {
    color: #888;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    &.remove-condition-btn {
      &:hover {
        color: rgb(244, 104, 104);
      }
    }

    &.add-condition-btn {
      font-size: 1.5em;
      margin-left: 30px;
      cursor: pointer;
      color: lighten(#2980b9, 15%);
      &:hover {
        color: #2980b9;
      }
    }
  }
}
</style>