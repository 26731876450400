<template>
  <div>
    <input class="form-control" type="date" :id="id" v-model="input" :min="min" :max="max" />
  </div>
</template>

<script>
export default {
  props: ["placeholder", "min", "max", "defaultValue", "label", "readonly"],
  data() {
    return {
      input: this.defaultValue,
    };
  },
  mounted() {
    this.$emit("input", this.input);
  },
  computed: {
    id() {
      return "text_input_" + this._uid;
    },
  },
  watch: {
    input(input) {
      this.$emit("input", input);
    },
    defaultValue(amount) {
      this.input = amount;
    },
  },
};
</script>