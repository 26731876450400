<template>
  <div class="actions-send-email">
    <div class="row">
      <div class="col-3">
        <label>Channel:</label>
        <select v-model="localConfiguration.channel" :value="localConfiguration.channel" class="form-select">
          <option value="single">Single</option>
          <option value="sentry">Sentry</option>
          <option value="slack-automations">Slack</option>
        </select>
      </div>
      <div class="col-3">
        <label>Level:</label>
        <select v-model="localConfiguration.level" :value="localConfiguration.level" class="form-select">
          <option value="debug">Debug</option>
          <option value="info">Info</option>
          <option value="warning">Warning</option>
          <option value="error">Error</option>
          <option value="critical">Critical</option>
        </select>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["value"],
  data() {
    return {
      localConfiguration: {
        level: "info",
        channel: "single",
      },
      baseUrl: process.env.VUE_APP_VAVEURL,
    };
  },
  mounted() {
    this.localConfiguration = this.value;
  },
  watch: {
    localConfiguration: {
      deep: true,
      handler() {
        this.$emit("input", this.localConfiguration);
      },
    },
  },
};
</script>
