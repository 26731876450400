<template>
  <input type="text" class="form-control" :id="id" v-model="input" :readonly="readonly" :placeholder="placeholder" />
</template>

<script>
export default {
  props: ["placeholder", "value", "label", "readonly", "defaultValue"],
  data() {
    return {
      input: this.defaultValue,
    };
  },
  mounted() {
    this.$emit("input", this.input);
  },
  computed: {
    id() {
      return "text_input_" + this._uid;
    },
  },
  watch: {
    input(input) {
      this.$emit("input", input);
    },
    value(value) {
      this.input = value;
    },
  },
};
</script>