<template>
  <div>
    <label>{{label}}</label>
    <input type="checkbox" class="form-check-input" :id="id" v-model="input" :checked="defaultValue" />
  </div>
</template>

<script>
export default {
  props: ["defaultValue", "label"],
  data() {
    return {
      input: this.defaultValue,
    };
  },
  mounted() {
    this.$emit("input", this.input);
  },
  computed: {
    id() {
      return "checkbox_input_" + this._uid;
    },
  },
  watch: {
    input(input) {
      this.$emit("input", input);
    },
    defaultValue(amount) {
      this.input = amount;
    },
  },
};
</script>