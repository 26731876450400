<template>
  <div>
    <div class="row mt-2" v-if="!productsConfirmed">
      <div class="col-4" v-if="!localIncludeProducts">
        <button class="vave-btn btn-blue" @click="addProducts()">Include Products</button>
      </div>
      <div class="col-4" v-if="localIncludeProducts">
        <select v-model="localWhichProducts" :value="localWhichProducts" class="form-select">
          <option :value="null" disabled>select</option>
          <option value="abandoned_cart">All products in the abandoned Cart or Order</option>
          <option value="completed_order">All products in the Completed Order</option>
          <option value="stock_subscription">Product in Stock Subscription</option>
          <!-- <option value="previous_order">All products from previous completed Order</option> -->
        </select>
      </div>
      <div class="col-4" v-if="localWhichProducts" @click="saveProducts()">
        <button class="vave-btn btn-outline-blue">Add Products</button>
      </div>
    </div>
    <div class="row" v-if="productsConfirmed">
      <div class="col-4">Include Products from: {{label}}</div>
      <div class="col-3 remove-products-btn" @click="removeProducts()">
        <i class="fa-solid fa-xmark"></i>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["whichProducts", "includeProducts"],
  data() {
    return {
      productsConfirmed: this.includeProducts === true,
      localIncludeProducts: this.includeProducts,
      localWhichProducts: this.whichProducts,
    };
  },
  computed: {
    label() {
      if (this.localWhichProducts == "abandoned_cart") {
        return "Abandoned Cart or Order";
      }
      if (this.localWhichProducts == "completed_order") {
        return "Completed Order";
      }
      if (this.localWhichProducts == "stock_subscription") {
        return "Stock Subscription";
      }
      return "Other Products";
    },
  },
  methods: {
    addProducts() {
      this.localIncludeProducts = true;
    },
    saveProducts() {
      this.productsConfirmed = true;
      this.$emit("productsUpdated", this.localWhichProducts);
    },
    removeProducts() {
      this.productsConfirmed = false;
      this.localIncludeProducts = false;
      this.localWhichProducts = null;
      this.$emit("productsUpdated", {});
    },
  },
};
</script>

<style lang="scss" scoped>
.actions-send-email {
  .remove-products-btn {
    cursor: pointer;
    color: #888;
    &:hover {
      color: rgb(244, 104, 104);
    }
  }
}
</style>