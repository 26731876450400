<template>
  <div class="coupon-component">
    <!-- Coupon -->
    <div v-if="!includeCoupon && !couponConfirmed">
      <button class="vave-btn btn-blue" @click="addCoupon()">Include Coupon</button>
    </div>

    <div v-else-if="couponConfirmed">
      <div class="mt-2 row existing-coupon">
        <div class="col-4" v-if="generatedCouponAmount">Generate new Coupon for: {{this.whichCouponDiscountType == 'fixed_cart' ? '£' + this.generatedCouponAmount : this.generatedCouponAmount + '%'}}</div>
        <div class="col-4" v-if="couponCode">Use existing Coupon: {{this.couponCode | upper}}</div>
        <div class="col-3 remove-coupon-btn" @click="removeCoupon()">
          <i class="fa-solid fa-xmark"></i>
        </div>
      </div>
    </div>

    <div class="row mt-2" v-show="includeCoupon && !couponConfirmed">
      <div class="col-3">
        <select class="form-select" v-model="whichCoupon">
          <option value="use_existing">Use existing code</option>
          <option value="generate_new">Generate new coupon per mail</option>
        </select>
      </div>

      <div class="col-2" v-if="whichCoupon == 'use_existing'">
        <input type="text" class="form-control" v-model="couponCode" />
      </div>

      <div class="col" v-if="whichCoupon == 'generate_new'">
        <div class="row">
          <div class="col-4">
            <select class="form-select" v-model="whichCouponDiscountType">
              <option value="percent">%</option>
              <option value="fixed_cart">£</option>
            </select>
          </div>
          <div class="col-8" v-if="whichCouponDiscountType == 'fixed_cart'">
            <div class="row">
              <div class="col-6">
                <input type="number" class="form-control" v-model="generatedCouponAmount" min="1" :max="whichCouponDiscountType == 'percent' ? '100' : null" />
              </div>
              <div class="col-6 d-flex align-items-center" v-if="generatedCouponAmount">£{{formatValue(generatedCouponAmount, 'decimal')}}</div>
            </div>
          </div>
          <div class="col-8" v-if="whichCouponDiscountType == 'percent'">
            <div class="row">
              <div class="col-6">
                <input type="number" class="form-control" v-model="generatedCouponAmount" min="1" max="100" />
              </div>
              <div class="col-6 d-flex align-items-center" v-if="generatedCouponAmount">{{formatValue(generatedCouponAmount, 'percentage')}}</div>
            </div>
          </div>
        </div>
      </div>

      <div class="col d-flex align-items-center" v-if="includeCoupon">
        <button class="vave-btn btn-outline-blue" @click="saveCoupon()" :disabled="!couponCode && !generatedCouponAmount">Confirm Coupon</button>
        <div class="ms-4 remove-coupon-btn" @click="removeCoupon()">
          <i class="fa-solid fa-xmark"></i>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { formatValue } from "./../../../../formatters";

export default {
  props: ["value"],
  data() {
    return {
      includeCoupon: false,
      whichCoupon: null,
      whichCouponDiscountType: null,
      generatedCouponAmount: null,
      couponCode: null,
      couponConfirmed: false,
    };
  },
  watch: {
    includeCoupon() {
      this.whichCoupon = "use_existing";
      this.couponCode = null;
      this.whichCouponDiscountType = null;
      this.generatedCouponAmount = null;
    },
    whichCoupon() {
      this.whichCouponDiscountType = null;
      this.generatedCouponAmount = null;
      this.couponCode = null;
    },
  },
  mounted() {
    if (this.value.include_coupon) {
      this.couponConfirmed = this.value.coupon_confirmed;
      if (this.value.generate_new) {
        this.whichCouponDiscountType = this.value.discount_type;
        this.generatedCouponAmount = this.value.amount;
      } else {
        this.couponCode = this.value.coupon_code;
      }
    }
  },
  methods: {
    formatValue,
    addCoupon() {
      this.includeCoupon = true;
    },
    removeCoupon() {
      this.includeCoupon = false;
      this.$emit("couponUpdated", {});

      // Reenable the form to add the coupon
      this.couponConfirmed = false;
    },
    saveCoupon() {
      let coupon = {};
      if (this.whichCoupon == "use_existing" && this.couponCode) {
        coupon.code = this.couponCode;
      }
      if (this.whichCoupon == "generate_new") {
        coupon.generate_new = true;
        coupon.discount_type = this.whichCouponDiscountType;
        coupon.amount = this.generatedCouponAmount;
      }
      this.$emit("couponUpdated", coupon);

      // Hide the form to add the coupon
      this.couponConfirmed = true;
    },
  },
  filters: {
    upper(value) {
      return value ? value.toUpperCase() : "";
    },
  },
};
</script>

<style lang="scss" scoped>
.actions-send-email {
  .remove-coupon-btn {
    cursor: pointer;
    color: #888;
    &:hover {
      color: rgb(244, 104, 104);
    }
  }
}
</style>